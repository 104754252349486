$(function () {
  $('.swiper-wrap').addClass('onComplete');
  $(".aco__btn").on("click", function () {
    $(this).next().slideToggle();
    $(this).toggleClass("active");
  });

  const collapseLinks = [
    ".result__linkBox__more",
    ".result__linkBox__more__goods",
    ".result__linkBox__more__mobile" ].join(",");

  const linkBoxMoreObj = {
    result__linkBox__more: "詳細を見る",
    result__linkBox__more__goods: "モットみる"
  };

  $(".card__wrap")
    .on("click", collapseLinks, function () {
      const prev = $(this).prev(".ac__content");
      const className = $(this).attr("class");
      
      if (prev.hasClass("vd-close")) {
        this.innerHTML = "閉じる";
      } else if (prev.hasClass("vd-open") && linkBoxMoreObj.hasOwnProperty(className)) {
        this.innerHTML = linkBoxMoreObj[className];
      }

      prev.toggleClass("vd-close")
        .toggleClass("vd-open");
    });

  $(".lower__page")
    .on("click", ".tabs a", function (e) {
      e.preventDefault();
      var target = $(this).attr('href');
      if ($(target).length) {
        $('.tab', $(this).closest('.tabs')).removeClass('active');
        $(this).closest('.tab').addClass('active');
        $('.panel', $(target).closest('.panels')).removeClass('active');
        $(target).addClass('active');
      }
    })
    .on("click", ".tab li", function (e) {
      e.preventDefault();
      var index = $('.tab li').index(this);
      $('.tabContent > li').css('display', 'none');
      $('.tabContent li').eq(index).css('display', 'block');
      $('.tab li').removeClass('select');
      $(this).addClass('select');
    });
});
